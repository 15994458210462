import { get, put, post, remove } from '@/helpers/http';

export function getCityById(id) {
  return get(`/cities/${id}`);
}

export function updateCityInformation(city) {
  return put(`/cities/${city.id}`, city);
}

export function createNewCity(city) {
  return post('/cities/', city);
}

export function removeCity(city) {
  return remove(`/cities/${city.id}`);
}

export function fetchCities(pagination = {}) {
  return get('/cities', { ...pagination });
}
