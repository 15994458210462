<template>
  <getecma-single-content-layout content-class="scroll pe--xxl">
    <template v-if="city" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row justify="center" class="d-flex align-items--center d-xs-flex-column">
        <getecma-col :xs="24" :span="17" justify="left">
          <getecma-city-view-information :city="city" />
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getCityById } from '@/modules/city/city.service';
import { MY_SPACE_URL } from '@/modules/my-space/my-space.constants';

import GetecmaCityViewInformation from '@/modules/city/components/CityViewComponent.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaCityViewPage',
  components: {
    GetecmaCityViewInformation,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Cidades', path: MY_SPACE_URL.cities.path },
        { name: 'Visualizar', path: this.$route.path },
      ],
      city: null,
      pagination: {
        page: 1,
        limit: 5,
      },
    };
  },
  computed: {
    city_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCityById(this.city_id)
        .then(data => {
          this.city = data;
        })
        .catch(() => toastError('Erro ao obter cidades por ID'));
    },
  },
};
</script>
