import * as cityResource from '@/modules/city/city.resource';

export function fetchCities(pagination = {}) {
  return new Promise((resolve, reject) =>
    cityResource
      .fetchCities(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getCityById(id) {
  return new Promise((resolve, reject) =>
    cityResource
      .getCityById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewCity(city) {
  return new Promise((resolve, reject) =>
    cityResource
      .createNewCity(city)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateCityInformation(city) {
  return new Promise((resolve, reject) =>
    cityResource
      .updateCityInformation(city)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeCity(city) {
  return new Promise((resolve, reject) =>
    cityResource
      .removeCity(city)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
