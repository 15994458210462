<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Nome:</b> {{ city.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Unidade Federativa (UF):</b> {{ city.federative_unit }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Aniversário da Cidade:</b> {{ city_anniversary }}
      </getecma-p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GetecmaCityViewInformation',
  props: {
    city: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      city_anniversary: null,
    };
  },
  mounted() {
    this.formatDate();
  },
  methods: {
    formatDate() {
      const data = new Date(this.city.citys_anniversary);
      this.city_anniversary = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    },
  },
};
</script>
